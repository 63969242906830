import { combineReducers } from "redux";
import {getCookieValue,datauserexist,datasucursalexist,getSucursal,GetFechaActual,getImporte, CalculaPrecioCompraPromediado, GetFirtsLastMonth, CalculaMinPrecioVenta,} from "../services/functions";
import { itemCompra } from "../static/dat/datos";


const colores ={"bg-gray-50":"","bg-cyan-100":"","bg-purple-100":"","bg-yellow-50":"","bg-green-100":"","bg-pink-100":"","bg-teal-100":"","bg-fuchi-100":"","bg-ambar-100":"","bg-gray-200":""},
PropsReportesDefault = {fecha_Inicial:GetFirtsLastMonth(new Date()).primer,fecha_Final:GetFechaActual().fecha,rutas:[],rutasModal:[],header:{}};

const Catalogos = (state = {}, action) => {
  switch (action.type) {
    case "SET_CATALOGOS":
      return action.catalogos ;
    case "UPDATE_CATALOGO":
      if(action.id){
        const catalog = {...state};
        catalog[action.id]=action.catalogo;
        return catalog;
      }else{ return {...state}}
    default:
      return state;
  }
};

const Clientes = (state = {}, action) => {
  switch (action.type) {
    case "ADD_CLIENT":
      if (action.item.descuento === undefined) {
        action.item.descuento = 0;
      }
      return action.item;
    case "CLEAN_CLIENT":
      return {};
    default:
      return state;
  }
};

const ClientesVenta = (state = {}, action) => {
  switch (action.type) {
    case "ADD_CLIENTVENTA":
      if (action.item.descuento === undefined) {
        action.item.descuento = 0;
      }
      return action.item;
    case "CLEAN_CLIENT_VENTA":
      return {};
    default:
      return state;
  }
};

const uniques = (state, data) => {
  //deseable pero no prioritario
  let find = false;
  let arrayproducts = [];

  state.forEach(function (item) {
    if (item.id === data.item.id) {
      //Agrega 1 a la cantidad ya existente
      let cantidadfinal = item.cantidad + 1;
      //Si la nueva cantiada supera la existencia deja por defecto la existencia del producto
      if(data.item.sucursales[getSucursal()].existencia < cantidadfinal){
        cantidadfinal --
      }

      arrayproducts.push({
        ...item,
        cantidad: cantidadfinal,
        importe: item.precio * cantidadfinal,
      });
      find = true;
    } else {
      arrayproducts.push(item);
    }
  });

  return [arrayproducts, find];
};

const ItemsVentaUniques = (state, data) => {
  //deseable pero no prioritario
  let find = false;
  let arrayproducts = [];
  const items = [...state];
  //console.log('reduc=>',data.item);

  items.forEach(function (item) {
    delete item.repeat;
    const itemProps = data.item.sucursales[getSucursal()];

    if (item.id === data.item.id && item.descripcion == data.item.descripcion) {
      find = true;
      let cantidadfinal = (item.cantidad + 1);
      const new_item = {...item,cantidad:cantidadfinal,repeat:true};

      (data.item?.existenciaCero?new_item.existenciaCero=data.item.existenciaCero:"")
      if(item.categoria!=="SERVICIO"){
        if(!data.item?.existenciaCero&&itemProps.existencia < cantidadfinal){ //Si la nueva cantiada supera la existencia deja por defecto la existencia del producto
            cantidadfinal --;
        }
      }
      arrayproducts.push({...new_item,importe:getImporte(item.precio,item.descuento,cantidadfinal)});
    } else {
      arrayproducts.push({...item});
    }
  });

  if(!find){
    const product = {...data.item};
    const itemProps = product.sucursales[getSucursal()];
    let cantidad = 0;
    (product.categoria=="MICA"?cantidad=2:cantidad=1);
    (product?.cantidad?cantidad=product.cantidad:"");
    if(!product?.existenciaCero&&cantidad>itemProps.existencia&&product.categoria!=="SERVICIO"){console.log('change'); cantidad = itemProps.existencia;}

    product.cantidad = cantidad;
    product.precioDesc = getImporte(product.precio,product.descuento,1);
    product.importe = getImporte(product.precio,product.descuento,cantidad);
    product.utilidad = product.importe - (itemProps.precioCompra*cantidad);
    arrayproducts.push(product)
  }

  return arrayproducts;
};

const itemsVenta = (state = [], action) => {
  switch (action.type) {
    case "ADD_ITEM":
       return ItemsVentaUniques (state, action);
    case "SET_ITEMSDATA":
      return action.items;
    case "SET_CANTIDAD":
      if (action.product){
        const items = [...state];
        items.map((item) =>{
          delete item.repeat;
          if(item.id===action.product.id&&item.descripcion==action.product.descripcion){
            const itemProps = item.sucursales[getSucursal()];
            item.cantidad = action.cantidad;
            item.importe = action.importe;
            //console.log('===>',itemProps)
            item.utilidad = action.importe-(itemProps.precioCompra*action.cantidad);
            if(action.product?.existenciaCero){
              item.existenciaCero=true;
            }
            if(item.existenciaCero==true&&action.cantidad<=item.sucursales?.[getSucursal()].existencia){
              delete item.existenciaCero
            }
          }
        });
        return items;
      }else{  return [...state]  }
    case "SET_DESCUENTO":
      if (action.product){
        const items = [...state];
        items.map((item) =>{
          if(item.id===action.product.id&&item.descripcion==action.product.descripcion){
            const itemProps = item.sucursales[getSucursal()];
            item.descuento = action.descuento;
            item.importe = getImporte(item.precio,action.descuento,item.cantidad);
            item.precioDesc = getImporte(item.precio,action.descuento,1);
            //console.log('===>',itemProps)
            item.utilidad = item.importe-(itemProps.precioCompra*item.cantidad);
          }
        });
        return items;
      }else{  return [...state]  }
    case "SET_PRECIOVENTA":
      if (action.product){
        const items = [...state];
        items.map((item) =>{
          if(item.id===action.product.id&&item.descripcion==action.product.descripcion){
            const itemProps = item.sucursales[getSucursal()];
            item.precio = action.precioVenta;
            item.importe = getImporte(item.precio,0,item.cantidad);
            item.utilidad = item.importe-(itemProps.precioCompra*item.cantidad);
            action.product?.mayorista?delete item.flagPrecioVenta:item.flagPrecioVenta = true;
            item.precioDesc = getImporte( action.precioVenta,item.descuento,1);
          }
        });
        return items;
      }else{  return [...state]  }
    case "RESET_PRECIOVENTA":
      if (action.product){
        const items = [...state];
        items.map((item) =>{
          if(item.id===action.product.id&&item.descripcion==action.product.descripcion){
            const itemProps = item.sucursales[getSucursal()];
            item.precio = itemProps.precioVenta;
            item.descuento = 0;
            item.importe = getImporte(item.precio,item.descuento,item.cantidad);
            item.utilidad = item.importe-(itemProps.precioCompra*item.cantidad);
            delete item.flagPrecioVenta;
          }
        });
        return items;
      }else{  return [...state]  }
    case "ELIMINAR_ITEM":
      if(action.item.categoria=="SERVICIO"){
        return state.filter((item) => item.descripcion !== action.item.descripcion);
      }else{
        return state.filter((item) => item.id !== action.item.id )
      }
    case "RESET_ITEM_VENTA":
      return [];
    default:
      return state;
  }
};

const Logueo = (state = datauserexist(), action) => {
  switch (action.type) {
    case "SET_LOGUEO":
      return action.datos;
    case "SET_ARRESUCURSAL_ESP":
      return {... state,sucursales:action.sucursales};
    case "SET_WIDTH_WINDOWS":
      return {...state,windowSize:action.width};
    default:
      return state;
  }
};


const Productos = (state = {categoria:{},marca:{},linea:{}, micatratamiento: []}, action) => {
  switch (action.type) {
    case "SET_CATEGORIA_PRODUCTO":
      return {categoria:(action.categoria||{}),marca:(action.categoria?.clave=="SE"?{clave:"INDI",valor:"INDIGO"}:{}),linea:{}, micatratamiento: []};
    case "SET_PRODUCTO":
      return action.producto;
    case "SET_MICA_PRODUCTO":
      return {...state,material:action.producto.material,tipo:action.producto.tipo};
    case "SET_GRADUADO_PRODUCTO":
      return {...state,graduado:action.graduado};
    case "SET_GRADUACION_PRODUCTO":
      return {...state,esferainicial:action.esferainicial,esferafinal:action.esferafinal,ciladdinicial:action.ciladdinicial,ciladdfinal:action.ciladdfinal}  

    case "SET_ALTA_SUCURSAL":
      return { ...state, sucursal: action.sucursal };
    case "SET_ALTA_PRECIOCOMPRA":
      return { ...state, preciocompra: action.preciocompra };
    case "SET_ALTA_PRECIOVENTA":
      return { ...state, precioventa: action.precioventa };
    case "SET_ALTA_DESCUENTOMAX":
      return { ...state, descuentomax: action.descuentomax };
    case "SET_ALTA_MODELO":
      return { ...state, modelo: action.modelo };

    case "SET_ALTA_BASE":
      return { ...state, micabase: action.micabase };

    case "SET_ALTA_ESFERAI":
      return { ...state, esferainicial: action.esferainicial };
    case "SET_ALTA_ESFERAF":
      return { ...state, esferafinal: action.esferafinal };
    case "SET_ALTA_CHECKSFI":
      return { ...state, checksfi: action.checksfi };
    case "SET_ALTA_CHECKSFF":
      return { ...state, checksff: action.checksff };
    case "SET_ALTA_ESFERASALTO":
      return { ...state, esferasalto: action.esferasalto };
      
    case "SET_ALTA_CILADDI":
      return { ...state, ciladdinicial: action.ciladdinicial };
    case "SET_ALTA_CILADDF":
      return { ...state, ciladdfinal: action.ciladdfinal };
    case "SET_ALTA_CHECKCILADDI":
      return { ...state, checkciladdi: action.checkciladdi };
    case "SET_ALTA_CHECKCILADDF":
      return { ...state, checkciladdf: action.checkciladdf };
    case "SET_ALTA_CILADDSALTO":
      return { ...state, ciladdsalto: action.ciladdsalto };

    case "SET_ALTA_TIPO":
      return { ...state, tipo: action.tipo };
    case "SET_ALTA_MICATRATAMIENTO":
      return { ...state, micatratamiento: action.micatratamiento };

    case "SET_ALTA_MATERIAL":
      return { ...state, material: action.material };
    case "SET_ALTA_MATERIAL2":
      return { ...state, material2: action.material2 };
    case "SET_ALTA_FORMA":
      return { ...state, forma: action.forma };

    case "SET_ALTA_CALIDAD":
      return { ...state, calidad: action.calidad };

    case "SET_ALTA_LCDURABILIDAD":
      return { ...state, lcdurabilidad: action.lcdurabilidad };
    case "SET_ALTA_COLOR":
      return { ...state, color: action.color };

    default:
      return state;
  }
};

const addRuta=(state,newruta)=>{
  let rutas = [...state];
  rutas.push(newruta)
  if(['menu','login'].includes(newruta)){
    rutas=[newruta];
  }
  return rutas;
}

const deleteRuta=(state)=>{
  let rutas = [...state];
  rutas.pop();
  return rutas;
}

const setRutas=(state,action)=>{
  let rutas=[...state];
  if(action.rutas.length==0){return state}
  action.rutas.forEach(rut=>{
    rutas.push(rut);
  })
  return rutas;
};

const Ruta = (state = [getCookieValue("sessionIndigo") ? "menu" : "login"],action) => {
  switch (action.type) {
    case "ADD_RUTA":
      return addRuta(state,action.ruta);
    case "RETURN_RUTA":
      return deleteRuta(state);
    case "SET_PREV_RUTA":
      return setRutas(state,action);
    case "SET_RUTA_REP":
        return {ruta:action.ruta,oldruta:action.oldruta,repRuta:state.ruta};
    default:
      return state;
  }
};

const SearchPersonal = (state = {}, action) => {
  switch (action.type) {
    case "SET_SEARCHPERSONAL":
      return action.personal;
    case "RESET_SEARCHPERSONAL":
      return {};
    default:
      return state;
  }
};

const SearchProveedor = (state = {}, action) => {
  switch (action.type) {
    case "CLEAN_PROVEEDOR":
      return {};
    case "SET_SEARCHPROVEEDOR":
      return action.proveedor;
    case "RESET_SEARCHPROVEEDOR":
      return {};
    default:
      return state;
  }
};

const VentaAPagar = (state = { total: 0 }, action) => {
  switch (action.type) {
    case "SET_VENTAAPAGAR":
      return action.ventaapagar;
    case "RESET_VENTAPAGAR":
      return {};
    default:
      return state;
  }
};

const itemsCompra = (state = [itemCompra], action) => {
  switch (action.type) {
    case "CLEAN_ITEMCOMPRA":
      return [itemCompra];
    case "SET_ITEM_COMPRA":
        return state.map((item) => item.index === action.item.index?action.item:item );
      case "CHANGE_CANTPC_COMPRA":
          return state.map((item) =>{
          if(item.id === action.item.id && item.index == action.item.index){
            const new_item = { ...item, 
              cantidad:action.item.cantidad, 
              precioCompra:action.item.precioCompra, 
              importe:(action.item.cantidad*action.item.precioCompra), 
            };
            
            new_item.precioCompraPromediado = CalculaPrecioCompraPromediado(action.item.precioCompra,action.item.cantidad,action.item.oldPrecioCompra,action.item.oldExistencia)||0;
            new_item.minPrecioVenta= CalculaMinPrecioVenta(new_item.precioCompraPromediado,action.item.categoria);
            new_item.precioVenta =  (new_item.minPrecioVenta > action.item.precioVenta? new_item.minPrecioVenta: action.item.precioVenta);
            return new_item;
           }else{return item}
          });
    case "ADD_ITEMCOMPRA":
      if (action.item.cantidad) {
        return uniquesCompras(state,action.item);
      } else {
        return [...state];
      }
    case "ELIMINAR_ITEMCOMPRA":
      return state.filter((item) => item.index !== action.index);
    case "SET_ITEMSCOMPRA":
      return action.items.map((item,index)=>({...item,index:index}));
    case "SET_INVENTARIOCOMPRA":
        return state.map((item) =>
          item.id === action.id
            ? { ...item, inventario: action.inventario}
            : item
        );
    default:
      return state;
  }
};

const HeaderVenta = (state = { pagos :[],ventaOk:false},action) => {
  switch (action.type) {
    case "CLEAN_HEADER_VENTA":
      return { pagos:[],ventaOk:false };
    case "SET_ID_VENTA":
      return { ...state, id: action.id };
    case "SET_CLIENTE_VENTA":
      return { ...state, cliente: action.cliente };
    case "SET_CANTIDAD_TOTAL_VENTA":
        return { ...state, cantidadTotal: action.cantidadTotal };
    case "SET_IMPORTE_VENTA":
        return { ...state, importe: action.importe };
    case "SET_OK_VENTA":
          return { ...state, ventaOk: action.ventaOk };
    case "SET_HEADER_VENTA":
        return action.headerVenta ;
    default:
      return state;
  }
};

const HeaderCompra = (state = { fechaReferencia: new Date().toISOString().substring(0, 10),pagos :[],compraOk:false,ventaOk:false,descuento:0,envio:0,compraMenor:false,traspaso:false},action) => {
  switch (action.type) {
    case "CLEAN_HEADERCOMPRA":
      return { fechaReferencia: new Date().toISOString().substring(0, 10),pagos:[],compraOk:false,ventaOk:false,descuento:0,envio:0,compraMenor:false,traspaso:false};
    case "SET_FECHAREFERENCIA":
      return { ...state, fechaReferencia: action.fechaReferencia };
    case "SET_REFERENCIA_COMPRA":
      return { ...state, referencia: action.referencia };
    case "SET_OLDREFERENCIA_COMPRA":
      return { ...state, oldRef: state.referencia,oldFecha: state.fechaReferencia };
    case "SET_IDCOMPRA":
      return { ...state, id: action.id };
    case "SET_PROVEEDORCOMPRA":
      return { ...state, proveedor: action.proveedor };
    case "SET_CANTIDADTOTALCOMPRA":
        return { ...state, cantidadTotal: action.cantidadTotal };
    case "SET_IMPORTECOMPRA":
        return { ...state, importe: action.importe };
    case "SET_COMPRAOK":
        return { ...state, compraOk: action.compraOk };
    case "SET_VENTAOK":
          return { ...state, ventaOk: action.ventaOk };
    case "SET_HEADER_COMPRA":
        return action.compra ;
    default:
      return state;
  }
};

const Ticket = (state = {}, action) => {
  switch (action.type) {
    case "SET_TICKET":
      return action.ticket;
    case "RESET_TICKET":
      return {};
    default:
      return state;
  }
};

const ventasCredito = (state = [{}], action) => {
  switch (action.type) {
    case "SET_VENTACREDITO":
      return action.ventascredito;
    case "RESET_VENTACREDITO":
      return [{}];
    default:
      return state;
  }
};

const uniquesCompras = (state, data) => {
  let arrayproducts = [itemCompra];
  arrayproducts =  arrayproducts.concat([...state,{...data,index:state.length}].filter(item=>item.index!==0))

  return arrayproducts;
};

const Datasucursal = (state = datasucursalexist(), action) => {
  switch (action.type) {
    case "SET_DATASUCURSAL":
      return action.datasucursal;
    default:
      return state;
  }
};

const Inventario = (state =[], action) => {
  switch (action.type) {
    case "ADD_INVENTARIO":
      if (action.item) {
        const [arrayproducts, find] = uniquesInventario(state, action);
        if (find) { 
          return arrayproducts;
        } else {
          return [
            ...arrayproducts,
            {
              ...action.item,
            },
          ];
        }
      } else {
        return [...state];
      }
    case "SET_PRECIOINVENTARIO":
      if (action.item) {
        const arrayproducts = setPrecioInventario(state, action);
        return arrayproducts;
      } else {
        return [...state];
      }
    case "ELIMINAR_INVENTARIO":
        return state.filter((item) => item.id !== action.id);
    case "SET_INVENTARIO":
        return action.inventario;
    case "RESET_INVENTARIO":
      return []
    default:
      return state;
  }
};

const PropsInventario =(state ={data:{schema:{},tablas:[]},items:[],sucursales:[],editItems:false,listprecios:{},colores:colores},action)=>{
  switch (action.type) {
    case "SET_PROPSINVENTARIO":
      return action.estado;
    case "SET_NEW_PROPSINVENTARIO":
      if(action.estado){
        const reset=action.estado;
        reset.data = {schema:{},tablas:[]};
        reset.items=[];
        reset.sucursales= [];
        reset.editItems = false;
        reset.listprecios={};
        reset.colores=colores;
        return reset;
      }else{ return state }
    
    case "RESET_PROPSINVENTARIO":
      return {data:{schema:{},tablas:[]},items:[],sucursales:(state?.sucursales?[...state.sucursales]:[]),editItems:false,listprecios:{},colores:colores};
    case "CLEAN_ALL_PROPSINVENTARIO":
      return {data:{schema:{},tablas:[]},items:[],sucursales:[],editItems:false,listprecios:{},colores:colores};
    case "SET_SUCURSALESINVENTARIO":
      return {...state, sucursales:action.sucursales}
    case "RESET_PROPSCOLORS":
      return {...state, listprecios:{},colores:colores}
    default:
      return state;
  }
};

const uniquesInventario = (state, data) => {
  let find = false;
  let arrayproducts = [];
  const itemData =data.item;

  state.forEach(function (item) {
    if (item.id === itemData.id) {
      const newItem = {
        ...item,
        cantidad: itemData.cantidad,
        descripcion : itemData.descripcion,
        guideline: itemData.guideline,
      };

      if(itemData?.precioCompraPromediado){ newItem.precioCompraPromediado = itemData.precioCompraPromediado; }
      if(itemData?.minPrecioVenta){ newItem.minPrecioVenta = itemData.minPrecioVenta}
      if(itemData?.precioVenta){ newItem.precioVenta =  itemData.precioVenta}

      arrayproducts.push(newItem);
      find = true;
    } else {
      arrayproducts.push(item);
    }
  });

  return [arrayproducts, find];
};

const setPrecioInventario = (state, data) => {
  let arrayproducts = [];
  state.forEach(function (item) {
    if(item.esfera==data.item.esfera){
      arrayproducts.push({
        ...item,
        precioVenta: data.item.precioVenta,
      });

    } else {
      arrayproducts.push(item);
    }
  });

  return arrayproducts;
};

const HeaderPes = (state = {fechaEstimada: GetFechaActual().fecha,proveedor:{},montoCompra:0,suppliersSelected:[],statusFilter:"",crearPedido:false,opcion:"pedidos",change:false}, action) => {
  switch (action.type) {
    case "SET_HEADER_PES":
      return action.pedido;
    case "SET_FILTER_PES":
      return {...state,statusFilter:action.filter,change:!state.change}
    case "RESET_HEADER_PES":
      return {fechaEstimada: GetFechaActual().fecha,proveedor:{},montoCompra:0,suppliersSelected:[],statusFilter:"",crearPedido:false,opcion:"pedidos",change:false};
    default:
      return state;
  }
};

const itemsPedidoEspecial = (state = [], action) => {
  switch (action.type) {
    case "ADD_ITEM_ESP":
      if (action.item.cantidad) {
        const [arrayproducts, find] = uniques(state, action);

        return [
            ...arrayproducts,
            {
              ...action.item,
              id:action.id,
            },
          ];
        
      } else {
        return [...state];
      }
    case "SET_CANTIDAD_ESP":
      return state.map((item) =>
        item.id === action.id
          ? { ...item, cantidad: action.cantidad, importe: getImporte(item.precioVenta,0,action.cantidad) }
          : item
      );
    case "SET_DESCRIPCION_ESP":
        return state.map((item) =>
          item.id === action.id
            ? { ...item, descripcion: action.descripcion }
            : item
        );
    case "SET_PRECIOVENTA_ESP":
      return state.map((item) =>
      item.id === action.id
        ? { ...item, precioVenta: action.precioVenta, importe: getImporte(action.precioVenta,0,item.cantidad)}
        : item
    );
    case "SET_PRECIOCOMPRA_ESP":
      return state.map((item) =>
      item.id === action.id
        ? { ...item, precioCompra: action.precioCompra }
        : item
    );

    case "SET_ITEM_ESP":
      return state.map((item) =>
      item.id === action.item.id
        ?action.item
       : item
      );
    case "SET_ITEMS_ESP":
      return action.items ;
    case "ELIMINAR_ITEM_ESP":
      return state.filter((item) => item.id !== action.id);
    case "RESET_ITEMS_ESP":
      return [];
    default:
      return state;
  }
};


const uniquesCash = (state, data) => {
  let find = false;
  let arrayproducts = [];

  state.forEach(function (item) {
    if (item.type === data.item.type) {
      arrayproducts.push({ ...item});
      find = true;
    } else {
      arrayproducts.push(item);
    }
  });

  return [arrayproducts, find];
};

const itemsCash = (state = [], action) => {
  switch (action.type) {
    case "ADD_ITEM_CASH":
      if (action.item.idCash) {
        const [arrayproducts, find] = uniquesCash(state, action);
        return [
            ...arrayproducts,
            {
              ...action.item,
            type:action.type,
            },
          ];
        
      } else {
        return [...state];
      }
    case "SET_ITEM_CASH":
        return state.map((item) =>
        item.type === action.type
          ? { ...item, idCash:action.item.idCash, type:action.item.type, fecha: action.item.fecha, concepto:action.item.concepto, importe:action.item.importe, clave:action.item.clave, metodo:action.item.metodo, cuenta:action.item.cuenta, contracuenta:action.item.contracuenta, descripcion:action.item.descripcion, flag:action.item.flag, edit:action.item.edit}
          : item
      );
    case "RESET_ITEMS_CASH":
      return [];
    case "SET_ITEMS_CASH":
      return action.items ;
    default:
      return state;
  }
};

const addRutaRep=(array,newruta)=>{
  let rutas = [...array];
  if(rutas.filter(ruta=>(ruta==newruta)).length==0){
    rutas.push(newruta)
  }
  return rutas;
}

const deleteRutaRep=(array)=>{
  let rutas = [...array];
  rutas.pop();
  return rutas;
}


const PropsReportes=(state = PropsReportesDefault, action)=>{
  switch (action.type) {
    case "SET_FECHAI_REP":
      return {...state,fecha_Inicial:(action.fechai>state.fecha_Final?state.fecha_Final:action.fechai)}
    case "SET_FECHAF_REP":
      return {...state,fecha_Final:(action.fechaf>GetFechaActual().fecha?GetFechaActual().fecha:action.fechaf)}  
    case "SET_FECHAS_REP":
      return {...state,fecha_Inicial:action.fechai,fecha_Final:action.fechaf}    
    case "SET_GLOBAL_REP":
      return {...state,global:action.global}
    case "RESET_REP":
      return {...PropsReportesDefault, fecha_Inicial:state.fecha_Inicial,fecha_Final:state.fecha_Final,openCalendar:state?.openCalendar}
    case "SET_INVENTARIO_REP":
      return{...state,inventario:action.inventario};
    case "SET_HEADER_REP":
      return {...state,header:action.header};
    case "SET_PROPS_REP":
        return action.props;
    case "SET_MACCOUNTS":
        return {...state,majorAccount:action.majorAccount};
    case "ADD_RUTA_REP":
      return {...state,rutas:addRutaRep(state.rutas,action.ruta)};
    case "DEL_RUTA_REP":
     return {...state,rutas:deleteRutaRep(state.rutas)};
     case "ADD_RUTAMODAL_REP":
      return {...state,rutasModal:addRutaRep(state.rutasModal,action.ruta)};
    case "DEL_RUTAMODAL_REP":
     return {...state,rutasModal:deleteRutaRep(state.rutasModal)};
    default:
      return state;
  }
}

const Datos=(state = {}, action)=>{
  switch (action.type) {
    case "ADD_DATOS":
      if(action.nombre){
        const new_datos =  {...state};
        new_datos[action.nombre] = action.datos;
        return new_datos;
      }else{ return {...state};}
    case "SET_DATOS":
      if(action.nombre){
        const new_datos =  {...state};
        new_datos[action.nombre] = action.datos;
        return new_datos;
      }else{return }
    case "RESET_DATOS":
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  itemsVenta,
  Clientes,
  Ruta,
  Logueo,
  Productos,
  Catalogos,
  SearchPersonal,
  SearchProveedor,
  itemsCompra,
  HeaderCompra,
  VentaAPagar,
  Ticket,
  ventasCredito,
  Datasucursal,
  ClientesVenta,
  Inventario,
  PropsInventario,
  HeaderPes,
  itemsPedidoEspecial,
  itemsCash,
  HeaderVenta,
  PropsReportes,
  Datos,
});
