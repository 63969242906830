import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useCatalogo, useCustomQuery } from '../../Hooks/useApis';
import { agregarProducto, setLogueo, setRuta } from '../../redux/Actions';
import { agregarItemVenta, getSucursal } from '../../services/functions';
import SearchSvg from '../../static/img/SearchSvg';
import Modalgeneric from '../modal/generic';
import Modalinfo from '../modal/info';
import Modaldanger from '../modal/danger';
import LoadingModal from '../modal/LoadingModal';
import TableModal from '../modal/TableModal';
import {motion} from 'framer-motion'
import InputSelectormap from './InputSelectormap';
import { useEffect } from 'react';
import Labelmoney from '../labelmoney';

const SearchLinea = ({addItem,Cliente,getDatos,getLinea,Ruta,itemsVenta,setInputFocus,inputFocus,setRuta,setLogueo,resetitemsVenta,resetClientes,resetClientesVenta,resetSearchPersonal,resetSearchProveedor,resetHeaderCompra,resetitemsCompra,resetHeaderVenta,resetVentaAPagar,resetTicket,resetventasCredito,resetInventario,resetPropsInventario,resetHeaderPes,resetitemsPedidoEspecial,resetitemsCash,resetPropsReportes,resetDatos}) => {
  const [modalModelo,setModalModelo]=useState({}),
  [modalLinea,setModalLinea]=useState([]),
  [modalErrorLinea,setmodalErrorLinea]=useState({}),
  [datos,setDatos]=useState([]),
  [datosLineas,setDatosLineas]=useState([]),
  [lineas,setLineas]=useState([]),
  [linea,setLinea]=useState(""),
  [modalLoading, setmodalLoading] = useState({}),
  [buttonErrorLinea,setButtonErrorLinea]=useState(false),
  [reset,setReset]=useState(false),
  [modalinfoConfig, setmodalinfoConfig] = useState({});
  const bad = useRef(), itemNoExist = useRef(),allItems=useRef([]),btnAllItems=useRef(true);

  const setInput = setInputFocus || null;


  function validaProduct(error) {
    setmodalinfoConfig({
      title: "Articulo no Valido",
      isOpen: true,
      message: (error.type=="existencia"
      ?"Sin Existencia. Desea venderlo de todas maneras? \n"
      :(error.type=="habilitar"
        ?"Producto desactivado/o sin entradas. Pedir al encargado revisar el producto: "
        :(error.type=="vendible"
          ?"Producto no disponible para venta: "
          :"El producto no existe en la Base de Datos :"
        )
      )
      )+error.item.id,
    });
  }

  const selectRowProduct=(item,e)=>{
    if(e.detail==2||e.key=='Enter'){
      closeModalModelo();
      if(getDatos){
        getDatos(item)
        return
      }
      if(Ruta=="venta"){
        const error = agregarItemVenta(item,addItem,Cliente.descuento,itemsVenta,false,Cliente?.mayorista);
        if(error){
          if(error.type=="existencia"){
            bad.current = error.type;
            itemNoExist.current = error.item;
            //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
          }
          validaProduct(error);
        }
        return;
      }
    }
  }

  const selectRowLinea=(item,e)=>{
    if(e.detail==2||e.key=="Enter"){
      closeModalLinea();
      getLinea(item);
    }
  }

  function closeModalModelo(e) {
     setModalModelo({ isOpen: false }); 
     setReset(!reset);
     setInput?setInputFocus(!inputFocus):null;
    }
  function closeModalLinea(e) { 
    setModalLinea({ isOpen: false }); 
    setReset(!reset);
    setInput?setInputFocus(!inputFocus):null;
  }
  const closeModalErrorLinea=()=>{
    bad.current=null; 
    itemNoExist.current=null; 
    setmodalErrorLinea({ isOpen: false }); 
  }
  function closeModalinfo(e) { 
    bad.current=null; 
    itemNoExist.current=null; 
    setmodalinfoConfig({ isOpen: false }); 
  }
  function openmodalLoading(title)  { setmodalLoading({ isOpen: true, title:(title||"nothing") }); }
  function closemodalLoading(e) {setmodalLoading({ isOpen: false });}

  function ValidarCampos()
  {
    if(Ruta==""){return false;}
    if(Ruta=="venta"&&!Cliente?.id){
      return true;
    }
    if(getDatos)
    {
      return false;
    }
    if(getLinea){
      return false;
    }

    return false;
  }

  function PeticionLinea() {
    const peticion ={
      indexName:"lineaIndex",
      hashKey:{valor:linea.valor,nombre:"linea"},
      sortKey:{valor:"LINEA",nombre:"type",operador:"eq"}
    }
    useCustomQuery(peticion,handleSuccessfulLinea,handleErrorLinea);
  }

  function PeticionProduct() {
    const peticion ={
      indexName:"lineaIndex",
      hashKey:{valor:linea.valor,nombre:"linea"},
      sortKey:{valor:"PRODUCT",nombre:"type",operador:"eq"}
    }
    if(Ruta=="venta"){
      peticion.filters=[{valor:"SERVICIO",operador:"ne",nombre:"categoria"}]
    }
    useCustomQuery(peticion,handleSuccessfulLineaProducto,handleErrorLineaProducto); 
  }

  const handleSuccessfulLineaProducto=(resp)=>{
    const productos = resp?.datos|| [];
    allItems.current=[];
    closemodalLoading();
    if (productos.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        title: "No se ha encontrado la clave en la base de datos.",
        message: "No existe el producto",
      });
      return;
    }
    if(getDatos){getDatos(productos); return}
    if(Ruta=="venta"){
      const prods = productos.filter(item=>(item.sucursales?.[getSucursal()]?.vendible==true));
      if(prods.length==0){
        //console.log('zero')
        setmodalinfoConfig({
          isOpen: true,
          title: "No se ha encontrado la clave en la base de datos.",
          message: "No existe el producto",
        });
      }
      else if(prods.length==1){
        //console.log('one')
          const error = agregarItemVenta(prods[0],addItem,Cliente.descuento,itemsVenta,false,Cliente?.mayorista);
          if(error){
            if(error.type=="existencia"){
              bad.current = error.type;
              itemNoExist.current = error.item;
              //useVentaPerdida(error.item.id,(error.item.cantidad-error.item.sucursales[getSucursal()].existencia),(resp)=>{console.log(resp,error.item.id)},(error)=>{})
            }
           validaProduct(error);
          }
      }else if (prods.length>1) {
        //console.log('all')
        allItems.current=productos;
        setDatos(productos.filter(prod => prod.sucursales?.[getSucursal()]?.existencia>0))
        setModalModelo({ isOpen: true });
      }
      return;
    }
  }
  const handleSuccessfulLinea=(resp)=>{
    closemodalLoading();
    //console.log('=>',resp)
    const lineas = resp?.datos || [];
    if (lineas.length === 0) {
        setmodalinfoConfig({
          isOpen: true,
          title: "No se encontro ningun Formato.",
          message: "El formato buscado no existe",
        });
      return;
    }
    if (lineas.length>0) {
      setDatosLineas(lineas)
      setModalLinea({ isOpen: true });
    }
  }
  const handleErrorLineaProducto=(error)=>{
    closemodalLoading();
    closeModalModelo();
    setmodalErrorLinea({
      isOpen: true,
      title: "No se ha encontrado la clave en la base de datos.",
      message: error?.message||error?.response?.data?.message || "",
    });
  }
  const handleErrorLinea=(error)=>{
    closemodalLoading();
    closeModalLinea();
    setmodalinfoConfig({
      isOpen: true,
      title: "No se ha encontrado la clave en la base de datos.",
      message: "LINEA:"+ error?.response?.data?.message || "",
    });
  }
  const PeticionLineas=()=>{
    openmodalLoading("Cargando Catalogos");
    useCatalogo("LINEAS",(resp)=>{
      setLineas(resp.opciones);
      closemodalLoading();
    },(error)=>{
      setButtonErrorLinea(true);
    });
  }

  function ResetRedux() {
    resetitemsVenta();
    resetClientes();
    resetClientesVenta();
    resetSearchPersonal();
    resetSearchProveedor();
    resetHeaderCompra();
    resetitemsCompra();
    resetHeaderVenta();
    resetVentaAPagar();
    resetTicket();
    resetventasCredito();
    resetInventario();
    resetPropsInventario();
    resetitemsPedidoEspecial();
    resetitemsCash();
    resetPropsReportes();
    resetDatos();
    resetHeaderPes();
  }

  const logOut = () => {
    document.cookie = "sessionIndigo=; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    localStorage.clear();
    setRuta("login");
    setLogueo({});
    localStorage.setItem("sucursal", JSON.stringify({}));
    ResetRedux();
  };

  useEffect(()=>{
    PeticionLineas();
  },[])

  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading?.title}</LoadingModal.Title>
        </LoadingModal.Body>
        <LoadingModal.Buttons>
         <button className='btn-gray '
          hidden={!buttonErrorLinea}
          onClick={e=>{
            logOut();
          }}>
            Cerrar Sesion
          </button>
          <button className='btn-blue '
          hidden={!buttonErrorLinea}
          onClick={e=>{
            setButtonErrorLinea(false);
            PeticionLineas();
          }}>
            Reintentar
          </button>
         
        </LoadingModal.Buttons>
      </LoadingModal>

      <Modalinfo config={modalinfoConfig} tab="200" closeModal={closeModalinfo}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue"
            name="modalinfo"
            tabIndex="201"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
          {bad.current=="existencia"?
          <button 
            type="button"
            name="modalinfo"
            tabIndex="200"
            className="btn-green mr-1"
            onClick={e=>{
              const newitem = itemNoExist.current;
              newitem.existenciaCero = true;
             
              const error = agregarItemVenta(newitem,addItem,Cliente.descuento,itemsVenta,false,Cliente?.mayorista);
              closeModalinfo();
              if(error){
                validaProduct(error);
              }
            }}
            >Vender
          </button>
          :null
          }
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modaldanger config={modalErrorLinea} tab="200" closeModal={closeModalErrorLinea}>
        <Modaldanger.Message title={modalinfoConfig.title}>
          {modalErrorLinea.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-blue"
            name="modaldanger"
            tabIndex="201"
            onClick={closeModalErrorLinea}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalgeneric w="size-w-90" h="size-h-90" config={modalModelo} closeModal={closeModalModelo}>
        <Modalgeneric.Title>Elija un modelo</Modalgeneric.Title>
        <Modalgeneric.Body>
          <TableModal
           name="tableModelo"
           searchinput={true}
           config={modalModelo}
           onEnterProduct={(item,e)=>{
            selectRowProduct(item,e);
          }}
            columns={[
              { header: "Clave", width:'w-2/12', accessor: "id" },
              { header: "Mod/Grad",width:"w-1/12", accessor:"medida"},
              {
                header: "Existencia", width:'w-1/12 text-center',
                // eslint-disable-next-line react/display-name
                code: (item) => (<label className='font-size'>{item?.sucursales[getSucursal()]?.existencia||0}</label>),
              },
              {
                header:"Precio",
                width:"w-1/12 text-center",
                // eslint-disable-next-line react/display-name
                code: (item) => (<div className='w-full grid grid-flow-col gap-1'>
                  <Labelmoney font="font-size" twoDecimal={true}>{item?.sucursales?.[getSucursal()]?.precioVenta||0}</Labelmoney>
                  {item?.sucursales?.[getSucursal()]?.vendible==false?<button className={'w-auto text-xs'} title='bloqueado'><i className='fas fa-ban text-red-500'></i></button>:null}
                </div>)
              },
              { header: "Descripción", width:"w-4/12 text-xs",  
               // eslint-disable-next-line react/display-name
               code: (item) => (<label className="font-size">{item.descripcion+(item?.categoria=="LENTE DE CONTACTO"?" "+item?.caracteristicas:"")}</label>)
              },
              { header: "Marca", width:"w-1/12 text-center",  accessor: "marca"},
            ]}
            onclickRow={(item,e) => {
              selectRowProduct(item,e);
            }}
            data={datos}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalModelo}
            className="btn-gray2"
          >
            Cancelar
          </button>
          <button className={"btn-red1"} title="productos sin existencia" onClick={e=>{
           (btnAllItems.current?setDatos(allItems.current):setDatos(allItems.current.filter(prod=>prod.sucursales?.[getSucursal()]?.existencia>0)))
            btnAllItems.current = !btnAllItems.current;
          }}>
            <i className="fas fa-ban text-bold"/>
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>

      <Modalgeneric w="size-modal-90" config={modalLinea} closeModal={closeModalLinea}>
        <Modalgeneric.Body>
          <Modalgeneric.Title>Elija una linea</Modalgeneric.Title>
          <TableModal
           name="tableLinea"
          searchinput={true}
          config={modalLinea}
            columns={[
              { header: "Linea", width:'w-1/12', accessor: "linea" },
              { header: "Codigo", width:'w-2/12',accessor:"id"},
              { header: "Descripción", width:"w-4/12 text-xs",  accessor: "descripcion" },
              { header: "Categoria", width:'w-1/12',  accessor: "categoria" },
            ]}
            onEnterProduct={(item,e)=>{
              selectRowLinea(item,e);
            }}
            onclickRow={(item,e) => {
              selectRowLinea(item,e);
            }}
            data={datosLineas}
          />
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closeModalLinea}
            className="btn-gray2"
          >
            Cancelar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <div className='size-form gap-1 items-center w-full'>
        <label className={"text-size font-bold "}>Linea:</label>
        <form 
        className={"flex w-11/12 md:w-48 justify-between"}
        onSubmit={e=>{
          e.preventDefault();
          if(linea?.clave){
            openmodalLoading("Cargando Productos");
            if(getLinea){
              PeticionLinea();
            }else{
              PeticionProduct();
            }
            return
          }
          if(getLinea){getLinea({});} 
        }}>
          <InputSelectormap
          catalogo={lineas}
          disabled={ValidarCampos()}
          placeh="NOMBRE PRODUCTO"
          name="linea"
          reset={reset}
          className={"w-full"}
          getvalor={(valor) => {
            setLinea(valor);
          }}/>
          <motion.button
          className={"w-4 h-4 self-center mx-1 md:mr-0 hover:text-blue-400"}
          type="submit"
          disabled={ValidarCampos()}
          style={{ transition: "all .15s ease" }}
          whileHover={{ scale: 1.2 }}
          whileTap={{
            scale: 0.8,
            rotate: 5,
            borderRadius: "100%"
          }}>
            <SearchSvg width="w-full" height="h-full" text={"text-blue-600"}/>
          </motion.button>
        </form>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(agregarProducto(item)),

  setRuta: (ruta) => dispatch(setRuta(ruta)),
  setLogueo: (datos) => dispatch(setLogueo(datos)),

  resetitemsVenta:()=>dispatch({type:"RESET_ITEM_VENTA"}),
  resetClientes:()=>dispatch({type:"CLEAN_CLIENT"}),
  resetClientesVenta:()=>dispatch({type:"CLEAN_CLIENT_VENTA"}),
  resetSearchPersonal:()=>dispatch({type:"RESET_SEARCHPERSONAL"}),
  resetSearchProveedor:()=>dispatch({type:"RESET_SEARCHPROVEEDOR"}),
  resetHeaderCompra:()=>dispatch({type:"CLEAN_HEADERCOMPRA"}),
  resetitemsCompra:()=>dispatch({type:"CLEAN_ITEMCOMPRA"}),
  resetHeaderVenta:()=>dispatch({type:"CLEAN_HEADER_VENTA"}),
  resetVentaAPagar:()=>dispatch({type:"RESET_VENTAPAGAR"}),
  resetTicket:()=>dispatch({type:"RESET_TICKET"}),
  resetventasCredito:()=>dispatch({type:"RESET_VENTACREDITO"}),
  resetInventario:()=>dispatch({type:"RESET_INVENTARIO"}),
  resetPropsInventario:()=>dispatch({type:"RESET_PROPSINVENTARIO"}),
  resetHeaderPes:()=>dispatch({type:"RESET_HEADER_PES"}),
  resetitemsPedidoEspecial:()=>dispatch({type:"RESET_ITEMS_ESP"}),
  resetitemsCash:()=>dispatch({type:"RESET_ITEMS_CASH"}),
  resetPropsReportes:()=>dispatch({type:"RESET_REP"}),
  resetDatos:()=>dispatch({type:"RESET_DATOS"}),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  utilMin: state.Catalogos.GLOBALCONFIGS.utilidadMinima,
  Proveedor: state.SearchProveedor,
  itemsVenta: state.itemsVenta,
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchLinea)