import React, { useState} from "react";
import { connect } from "react-redux";
import { useGetPedidosEsp, useInsertVentaEspecial } from "../../Hooks/useApis";
import { ceros_decimal, GetFechaEstimada, getIdUser, GetItemsPes, getNombre, getSucursal, moneyToValue } from "../../services/functions";
import InputPrecioPanel from "../InputPrecioPanel";
import Modalinfo from "../modal/info";
import LoadingModal from "../modal/LoadingModal";
import {motion} from 'framer-motion';
import Labelmoney from "../labelmoney";


const AddProduct=({config})=>{
  const Cliente = config?.Cliente;
  const key = config.index;
  const changeProd=(item)=>{
    let prods = [...config.datos];

    prods[config.index].cantidad = item.cantidad;
    prods[config.index].descripcion =  item.descripcion;
    prods[config.index].precioVenta =  item.precioVenta;
    config.setdata(prods);
  }

  return (<div key={key} className="w-full flex flex-row gap-2 justify-center">
              <input
                type="number"
                min={1}
                key={"cant"+key}
                className="border-form bg-white w-full md:w-28 font-size"
                style={{ transition: "all .15s ease" }}
                value={config.item.cantidad}
                onChange={e=>{
                  let cantidad = 0;
                  const value = parseInt(e.target.value);
                  if(!isNaN(value)){
                    let val =value
                   if(Number.isInteger(val)){ cantidad = val; }
                  }
                  changeProd({...config.item,cantidad:cantidad})
                }}
                disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
              />
              <input
                type="text"
                className="border-form bg-white w-full uppercase font-size  ml-0 md:ml-2 "
                name="pedido"
                key={"pedido"+key}
                value={config.item.descripcion}
                placeholder="Descripción del pedido"
                style={{ transition: "all .15s ease" }}
                onChange={e=>{
                  changeProd({...config.item,descripcion:e.target.value.toUpperCase()})
                }}
                disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
              />
              {/*Cliente?.mayorista?
                <>
                <input
                type="number"
                min={1}
                key={"cant"+key}
                className="border-form bg-white w-full md:w-20 font-size"
                style={{ transition: "all .15s ease" }}
                value={config.item.cantidad}
                onChange={e=>{
                  let cantidad = 0;
                  const value = parseInt(e.target.value,10);
                  if(Number.isInteger(value) && value>=1){ cantidad = value; }
                  changeProd({...config.item,cantidad:cantidad})
                }}
                disabled={["undefined","Inexistente"].includes(Cliente.nombre)}
                />
                <Labelmoney twoDecimal={true} className="w-full md:w-28" >{config.item.precioVenta}</Labelmoney>
                </>
              :*/<InputPrecioPanel
                className="bg-white w-full md:w-28 ml-0 md:ml-2"
                min={1}
                key={"pv"+key}
                form={true}
                reset={Cliente}
                defaultValue={0}
                newValor={config.item.precioVenta}
                setValor={(valor)=>{
                  changeProd({...config.item,precioVenta:moneyToValue(valor)})
                }}
                disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
              />
              }
          <button
          key={"delete"+key}
          className={(![undefined,"Inexistente"].includes(Cliente.nombre)?"text-red-500 hover:text-red-400 text-xl mr-2":"hidden")}
          onClick={(e) => { config.deletepago(config.index);  }}>
            <i className="fas fa-times-circle"></i>
          </button>
  </div>)

}

const InsertPedidoEspecial = ({ Cliente,cleanCliente,setItemsPes,cleanPes,allSucursales,headerPes}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [pedido,setPedido]=useState({}),
  [addProd,setAddProd]=useState([{cantidad:1,descripcion:"",precioVenta:0}]);

  const agregarItem=(pedido)=> {
    const peticion = {
      "items":pedido.items,
      "cliente":Cliente.id,
      "personal": getIdUser(),
      "nombrePersonal": getNombre(),
      "remitente": pedido.remitente,
      "sucursal": getSucursal(),
      "importe":pedido.items.reduce((a, b) => a + (b.precioVenta), 0),
      'fechaEstimada':GetFechaEstimada(new Date().toISOString()),
    }
    openmodalLoading();
    useInsertVentaEspecial(peticion,handleSuccessful,handleError);
  }

  const cleanDatos=()=>{ setAddProd([{cantidad:1,descripcion:"",precioVenta:0}]) }

  const handleSuccessful = (data) => {
    cleanCliente();
    cleanDatos();
    cleanPes();
    useGetPedidosEsp("","",[getSucursal()],headerPes.opcion,funcionExitoPes,headerPes.opcion,handleError);
  };
  const funcionExitoPes = (data) => {
    closemodalLoading();
    if (data.pedidos.length === 0) {
      setmodalinfoConfig({
        isOpen: true,
        message: "No hay pedidos pendientes'",
      });
    }
    setItemsPes(GetItemsPes(data.pedidos));
  };

  const handleError = (error) => {
    closemodalLoading();
    setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message, });
  };
  const closeModalinfo=(e)=> { setmodalinfoConfig({ isOpen: false });}
  const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
  const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); }

  const deletePago = (index) => {
    let newPagos = [...addProd];
    let misPagos = [];
    for (let i = 0; i < newPagos.length; i++) {
      if (i !== index) { misPagos.push(newPagos[i]); }
    }
    setAddProd(misPagos);
  };
  const validaAddProds=()=>{
    let valido = true;
    if(!pedido?.remitente){ valido=false; }
    if(addProd.length<1){ valido = false; }
    addProd.forEach(prod=>{
      if(prod.cantidad==0||prod.precioVenta==0||prod.descripcion.trim()==""){
        valido= false;
      }
    })

    return valido
  }

  return (
    <>
      <LoadingModal config={modalLoading} z="z-40" >
          <LoadingModal.Body>
            <LoadingModal.Title>{modalLoading.titulo||"Cargando Pedidos"}</LoadingModal.Title>
          </LoadingModal.Body>
      </LoadingModal>
      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={modalinfoConfig.title}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <div className={(headerPes?.crearPedido?"block border border-gray-400 bg-gray-100 rounded-xl px-2items-center":"hidden")}>
        <div className="size-form w-full gap-2 "> 
          <div className="w-full md:w-auto">
              <label className="textf">Surtidor</label>
              <select
              defaultValue={""}
              className=" border-form w-full md:w-32"
              disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
              onChange={e=>{
               setPedido({...pedido,remitente:e.target.value.toUpperCase()})
              }}>
                {<option key={-1} defaultValue=""></option>}
                {allSucursales.sort().map((item,index)=>(
                    <option key={index} defaultValue={item}>{item}</option>
                ))}
              </select>
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-row justify-between w-full gap-3">
              <label className="textf w-28">Cantidad</label>
              <label className="textf w-full text-center">Descripcion</label>
              {/*Cliente?.mayorista?<label className="textf w-20">%</label>:""*/}
              <label className="textf w-28">Precio V</label>
            </div>
    
            <div className="flex flex-col w-full gap-1">
            {addProd.length>0?
            addProd.map((item, index)=>{
                return <AddProduct
                key={index}
                config={{
                  item:item,
                  setdata: setAddProd,
                  datos: addProd,
                  index: index,
                  deletepago : deletePago,
                  Cliente: Cliente
                }}/>
              })
              :""}           
            </div>
          </div>
             <motion.button 
                className="my-auto rounded-full h-6"
                whileHover={{scale:1.2}}
                whileTap={{scale: 0.8,  rotate: 5, borderRadius: "100%"}}
                disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
                onClick={e=>{
                  let prods = [...addProd];
                  prods.push({cantidad:1,descripcion:"",precioVenta:0})
                  setAddProd(prods);
               }}>
                  <i className={"fas fa-plus-circle text-2xl  text-blue-600 hover:text-blue-700 "+([undefined,"Inexistente"].includes(Cliente.nombre)?"opacity-30":"")}/>
            </motion.button>
            <div className="my-auto ml-1">
              <button
                className={(validaAddProds()?"btn-green1 w-full md:w-24":"hidden")}
                type="button"
                disabled={[undefined,"Inexistente"].includes(Cliente.nombre)}
                style={{ transition: "all .15s ease" }}
                onClick={e=>{
                  const new_pedido = {...pedido,items:addProd}
                    agregarItem(new_pedido);
                    //setCantidad(1);
                }}
              >
                Pedir
              </button>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end">
            <label className="font-size">Total: </label>
            <Labelmoney className="font-size ml-1" twoDecimal={true}>{addProd.reduce((a, b) => a + (b.precioVenta), 0)}</Labelmoney>
          </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  cleanCliente:()=> dispatch({type:"CLEAN_CLIENT"}),
  setItemsPes:(items)=> dispatch({type:"SET_ITEMS_ESP",items:items}),
  cleanPes:()=> dispatch({type:"RESET_ITEMS_ESP"}),
});

const mapStateToProps = (state) => ({
  Cliente: state.Clientes,
  allSucursales: state.Catalogos.sucursales,
  headerPes: state.HeaderPes,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsertPedidoEspecial);
