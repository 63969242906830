import React, { useEffect, useRef, useState } from 'react'
import { useGetPes } from '../../Hooks/useApis';
import { AddDiasFecha, FechaLocalToUTC, getArrayAsync, GetFechaActual, getSucursal, getTotal, moneyToValue, RestaFechas, tipoCelda } from '../../services/functions';
import Modalgeneric from '../modal/generic';
import InputPrecioPanel from '../InputPrecioPanel'
import { connect } from 'react-redux';
import { setRuta } from '../../redux/Actions';
import Modalinfo from '../modal/info';
import LoadingModal from '../modal/LoadingModal';
import TableReactWindows from '../Reportes/Cash/TableReactWindows';
import BodyTicketPes from '../Tickets/BodyTicketPes';

const BuscaTicketPes=({setRuta,SetTicket,itemsVenta})=> {
    const [modalgenericonfig, setmodalgenericonfig] = useState({}),
    [modalinfoConfig, setmodalinfoConfig] = useState({}),
    [tickets, setTickets] = useState([]),
    [errors,setErrors]=useState(''),
    [fecha,setFecha]=useState({inicial:GetFechaActual().fecha,final:GetFechaActual().fecha}),
    [importe,setImporte]=useState({inicial:0,final:0}),
    [fechaMin,setFechaMin]=useState(),
    [Ticket,setTicketSelect]=useState({}),
    [modalLoading, setmodalLoading] = useState({}),
    [garantia,setGarantia]=useState({responsable:"",ticket:"",descripcion:"",items:[],referencia:""}),
    [enable,setEnable]=useState(false);

    const inputCliente = useRef(),title = useRef("Sin Datos"),inputTicket=useRef("");

    const openmodalLoading = (titulo) => {setmodalLoading({ isOpen: true, titulo:titulo });};
    const closemodalLoading = (e) => {setmodalLoading({ isOpen: false }); };
    const openmodalgeneric = (e) => { setmodalgenericonfig({ isOpen: true }); };
    const closemodalgeneric = (e) => { setmodalgenericonfig({ isOpen: false }); };
    

    const closeModalinfo=(e)=> { setmodalinfoConfig({ isOpen: false }); }
    const openModalInfo=(error)=>{ setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message || error,}); }

    const funcionExito =(pedidos,tk)=>{
      if (pedidos.length === 0) {
        openModalInfo("No existe el pedido: '" + tk + "'");
      }else{
        pedidos.map(pes=>{
          pes.fechaTk = pes.id.replace(getSucursal()+"#","");
          pes.credito = pes.pagado == pes.importe?false:true;
          return pes
        })
        setTickets(pedidos);
      }
      setErrors('');
      setEnable(false);
      closemodalLoading();
    }

    const QueryTickets=async()=>{
      setEnable(true);
      setTickets([]);
      setTicketSelect({});
      const tk = inputTicket.current.value.trim().toUpperCase();
      
      if(tk){
        try{
          openmodalLoading();
          const resp = await useGetPes(tk);
          const pedidos = resp?.data?.items||[];
          funcionExito(pedidos,tk);
        }catch (error) {
          closemodalLoading();
          setEnable(false);
        }
        return;
      }
      let filtros = [/*{valor:'',operador:'exists',nombre:'pagado'}*/];
      let fechaI=fecha.inicial,fechaF=fecha.final;
      
      if(RestaFechas(fechaI,fechaF)<0){
        fechaI= fecha.inicial;
        fechaF=fecha.final;
      }
    
      if(inputCliente.current.value==""){
        if(RestaFechas(fechaI,fechaF)>31){
          setErrors('El rango de fechas no puede ser mayor a 30 dias,cuando no se especifica el cliente');
          setEnable(false);
          return;
        }
      }
      if(importe.inicial>0||importe.final>0){
        let importeI=importe.inicial,importeF=importe.final;

        if(importeI>0&&importeF==0){
          filtros.push({valor:moneyToValue(importeI),operador:'gte',nombre:'importe'})
        }else if(importeF>0&&importeI==0){
          filtros.push({valor:moneyToValue(importeF),operador:'lte',nombre:'importe'})
        }else{
          if(importeI>importeF){
            importeI = importe.final;
            importeF =  importe.inicial;
          }
          filtros.push({valor:[moneyToValue(importeI),moneyToValue(importeF)],operador:'between',nombre:'importe'})
        }
      }
          
      openmodalLoading();
      const peticion ={
          indexName:"reverseIndex",
          hashKey:{valor:("PE#"+getSucursal()),nombre:"type"},
          sortKey:{valor:[getSucursal()+"#"+FechaLocalToUTC(fechaI),getSucursal()+"#"+FechaLocalToUTC(fechaF,23,59,59)],operador:"between",nombre:"id"},
          filters:filtros
      }
      if(inputCliente.current.value!==""){
        peticion.indexName="asociadoIndex";
        peticion.hashKey ={valor:("PE#"+getSucursal())+"#"+(inputCliente.current.value.toUpperCase()=="SYS"?inputCliente.current.value.toUpperCase()+"#"+getSucursal():inputCliente.current.value.toUpperCase()),nombre:"sujetoAsociado"};
        peticion.sortKey ={valor:[getSucursal()+"#"+FechaLocalToUTC(fechaI),getSucursal()+"#"+FechaLocalToUTC(fechaF,23,59,59)],operador:"between",nombre:"id"};
      }

      try {
        const resp = await getArrayAsync(peticion,[]);
        funcionExito(resp,tk);
      }catch (error) {
        closemodalLoading();
        setEnable(false);
      }
    }
    
    useEffect(()=>{
        if(!fecha.final){return;}
        setFechaMin(AddDiasFecha(fecha.final,-30));
    },[fecha.final])

    useEffect(()=>{
      if(garantia.items.length==0){return}
      let new_garantias={...garantia};
      itemsVenta.forEach(item=>{
        let exist = new_garantias.items.filter(gar=>(gar.id==item.id));
        if(exist.length==0){
          const new_item = {...item};
          const propSucursales = item.sucursales?.[getSucursal()];
          new_item.cantidadVendida = item.cantidad;
          new_item.precioCompra = propSucursales.precioCompra;
          
          new_garantias.items.push(new_item);
        }
      });

      setGarantia(new_garantias);
    },[itemsVenta])

  return (
      <>
      <LoadingModal config={modalLoading}  >
        <LoadingModal.Body>
          <LoadingModal.Title>{modalLoading.titulo||"CARGANDO"}</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>
        <Modalinfo config={modalinfoConfig}>
          <Modalinfo.Message title={title.current}>
            {modalinfoConfig.message}
          </Modalinfo.Message>
          <Modalinfo.Buttons>
            <button
              type="button"
              className="btn-blue1"
              onClick={closeModalinfo}
            >
              Cerrar
            </button>
          </Modalinfo.Buttons>
        </Modalinfo>

        <Modalgeneric front="z-20" w="size-w-60" h="size-h-90" config={modalgenericonfig} >
        <Modalgeneric.Body >
          <div className={"size-4-none w-full h-full overflow-y-auto"}>
            <div className='flex flex-col gap-2 w-full h-full justify-start border border-blue-200 ' >
                <label type="text" disabled={true} className='w-full text-red-400 text-sm font-semibold text-center break-words' >{errors}</label>
                <div className='size-3 items-center'>
                    <label className=" font-semibold">Fecha I:</label>
                    <input 
                    className='border-form w-full md:col-span-2' 
                    type='date'
                    max={fecha.final}
                    defaultValue={fecha.inicial}
                    onBlur={e=>{ setFecha({...fecha,inicial:e.target.value}) }}
                    ></input>
                </div>
                <div className='size-3 items-center'>
                    <label className='font-semibold'>Fecha F:</label>
                    <input 
                    className='border-form w-full md:col-span-2' 
                    type='date'
                    max={GetFechaActual().fecha}
                    onBlur={e=>{ setFecha({...fecha,final:e.target.value}) }}
                    defaultValue={fecha.final}
                    ></input>
                </div>
                <div className='w-full rounded-lg border-gray-500 border-dashed border flex flex-col gap-2'>
                  <div className='size-3 items-center'>
                      <label className=' font-semibold'>{"Importe Mayor:"}</label>
                      <InputPrecioPanel
                      min={0}
                      form={true}
                      className={"bg-white md:col-span-2"}
                      setValor={(e)=>{setImporte({...importe,inicial:parseFloat(e)});}}
                      />
                  </div>
                  <div className='size-3 items-center'>
                      <label className='font-semibold'>{"Importe Menor:"}</label>
                      <InputPrecioPanel
                      min={0}
                      form={true}
                      className={"bg-white md:col-span-2"}
                      setValor={(e)=>{setImporte({...importe,final:parseFloat(e)});}}
                      />
                  </div>
                </div>
                <div className='size-3  items-center'>
                    <label className=' font-semibold'>Cliente:</label>
                    <input className='border-form md:col-span-2 uppercase' type='text'  ref={inputCliente}></input>
                </div>
                <div className='size-3  items-center'>
                    <label className=' font-semibold'>Ticket:</label>
                    <input className='border-form md:col-span-2 uppercase' type='text' onKeyDown={e=>{if(e.key=="Enter"){QueryTickets();}}} ref={inputTicket}></input>
                </div>
                <button 
                    className='btn-green w-24 items-center'
                    disabled={enable}
                    type="button"
                    onClick={e=>{
                      QueryTickets();
                    }}
                >Buscar</button>
            </div>
            
            <div className='w-full h-full md:col-span-3 gap2 grid grid-rows-3'>
              <div className='w-full h-40 md:h-full relative'>
                <TableReactWindows
                  bgRow="bg-white"
                  bgHeader="bg-blue-800 text-white"
                  onRowClick={({e,row})=>{
                    if(e.detail==2||e.key=="Enter"){
                      setTicketSelect({
                        item:row,
                        pagos:row.pagos,
                        pagado:row.pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0)
                      })
                    }
                  }}
                  columns={[
                    { header: "Pes", width:80, key: "shortId", type:tipoCelda.text, filter:true},
                    { header: "Cliente", width:120, key: "cliente", type:tipoCelda.text, filter:true},
                    { header: "Fecha", width:100, key: "fechaTk", type: tipoCelda.date, filter:true},
                    { header: "Vendedor", width:120, key:"personal", type: tipoCelda.text, filter:true},
                    { header: "Total", width:110, key: "importe", type: tipoCelda.money, filter:true, footer:true, function: getTotal},
                    { header: "Status", width:110, key: "status", type: tipoCelda.text, filter:true},
                    { header: "Pagado", width:70, key: "credito", type: tipoCelda.text, filter:true,
                       // eslint-disable-next-line react/display-name
                      content: ({row,index}) => (
                        <div className='flex justify-center'> <i className={"fas fa-circle "+(!row.credito?"text-teal-400":"text-red-600")}/></div>
                      )
                    }
                  ]}

                  data={tickets}
                />
              </div>
              <div className='w-full h-40 md:h-full row-span-2'>
                <div className={'size-form w-full overflow-y-auto h-full border border-gray-500'}>
                  {(JSON.stringify(Ticket)!=="{}")?
                    <div className='w-full '>
                      <BodyTicketPes Ticket={Ticket} textSize={"text-2xs lg:text-xs"}/>
                    </div>
                    :""}
                </div>
              </div>
            </div>
          </div>
       
        </Modalgeneric.Body>
        <Modalgeneric.Buttons >
          <button
            type="button"
            className="btn-blue1"
            disabled={JSON.stringify(Ticket)=="{}"}
            onClick={(e) => {
              const ticket = {
                item:Ticket,
                pagos:Ticket.pagos,
                pagado:Ticket.pagos.reduce((total,pago)=> total + parseFloat(pago.monto),0),
                id:Ticket.id,
                cliente:Ticket.clientE,
                subtotal:Ticket.importe,
                iva:0,
                total: Ticket.importe
              };
              
              SetTicket(ticket);
              setRuta("ticketCopiaPes");
            }}
          >
            Imprimir
          </button>
          <button
            type="button"
            className="btn-gray2 mx-1"
            onClick={closemodalgeneric}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


        <div className='size-form w-full justify-center group'>
            <button
            className='btn-gray2 w-full'
            onClick={e=>{ openmodalgeneric(); }}
            >
              <i className="fas fa-search" />
            </button>
            <span className={" tooltiptext"}>
              Buscar Ticket
            </span>
        </div>
      </>
  )
}

const mapStateToProps = (state) => ({
  Ruta: state.Ruta,
  Motivos: state.Catalogos.GARANTIAS,
  itemsVenta: state.itemsVenta,
  Categorias: state.Catalogos.CATEGORIAS.opciones
});

const mapDispatchToProps = (dispatch) => ({
  setRuta: (ruta) => dispatch(setRuta(ruta)),
  SetTicket: (datos) =>
    dispatch({
      type: "SET_TICKET",
      ticket: datos,
    }),
    setItemsVenta: (item) =>
    dispatch({
      type: "SET_ITEMSDATA",
      items: item,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(BuscaTicketPes)