import React, { useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import LoadingModal from "../modal/LoadingModal";
import Modaldanger from "../modal/danger";
import Modalsuccess from "../modal/success";
import Modalgeneric from "../modal/generic";
import Selectormap from "../Productos/Selectormap";
import { useCustomQuery, useGetProduct, useGetUsuariosAsync, useInsertMerma } from "../../Hooks/useApis";
import { getSucursal, moneyToValue, redondeo } from "../../services/functions";
import TableCompra from "../Compras/TableCompra";
import InputPrecioPanel from "../InputPrecioPanel";
import Labelmoney from "../labelmoney";
import TableModal from "../modal/TableModal";
import Modalinfo from "../modal/info";
import LabelNumber from "../LabelNumber";


const ModalMerma = ({config,setModal,Motivos}) => {
  const item0 = {index:0,id:"",descripcion : "",oldExistencia : 1,cantidad : 1,importe : 0,precioCompra: 0,type : ""};
  const [modalLoading, setmodalLoading] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modalTablaConfig, setmodalTablaConfig] = useState(false),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [itemsAdjust, setItemsAdjust] = useState([item0]),
  [dataproduct, setdataproduct] = useState([]),
  [add,setAdd]=useState(undefined),
  [garantia,setGarantia] = useState({personal:"",}),
  [usuarios,setUsuario]=useState([]);
  const title=useRef(""), inputs =useRef({}),mot = Motivos.sort(),
  selectUser=useRef(""),selectMotivo=useRef("");

  const changeCantidad=(newItem)=>{
    let item = {...newItem};
    item.importe = (item.cantidad*newItem.precioCompra);
    setItem(item);
  }

  const setItem=(new_item)=>{
    setItemsAdjust([...itemsAdjust].map((item) => item.index === new_item.index?new_item:item ));
  }
  const eliminarItemCompra=(id)=>{
    setItemsAdjust(itemsAdjust.filter((item) => item.id !== id))
  }
  const addItem=(new_item)=>{
    let new_items = [{...item0}];
    new_items =  new_items.concat([...itemsAdjust,{...new_item,index:itemsAdjust.length}].filter(item=>item.index!==0))
    setItemsAdjust(new_items);
    setAdd(!add);
  }

  function focusProduct (id,campo){
    let index = 0, find = false;
    itemsAdjust.forEach(producto=>{
      if (producto.id == id&&producto.descripcion!==""){
        find = true;
        index = producto.index;
      }
    })
    if(find){
      setItem(item0);
      //inputs.current["0id"].value = "";
      inputs.current.[index+campo].focus();
      inputs.current[index+campo]?.select?inputs.current[index+campo].select():""
      
    }
    return find;
  }
  function agregarItem(item) {
    const newitem = itemsAdjust[0],sucursal = item.sucursales?.[getSucursal()];
      setItem({...newitem,
        oldExistencia:sucursal?.existencia||0,
        cantidad:1,
        descripcion: item.descripcion,
        precioCompra: redondeo(sucursal?.precioCompra || 0),
        importe: redondeo(sucursal?.precioCompra || 0),
        id: item.id,
        categoria: item.categoria,
        marca: item.marca,
        linea: item.linea,
        responsable:"",
        ticket:"",
      })
  }

  function validProductId(id){
    let x = true
    if(id.length<4){x=false;}
    return x
  }



  const funcionExito = (dataproductos) => {
    const data = dataproductos.productos;
    closemodalLoading();
    //
    if(dataproductos.linea){
      setmodalinfoConfig({ isOpen: true,message: "Los ajustes de Micas debe hacerse en el Modulo de Inventario ",});
      return;
    }else{
      if (data.length === 0) {
        title.current ="Producto No Encontrado";
        setmodalinfoConfig({ isOpen: true,message: ("No existe el producto "+itemsAdjust[0]?.id),});
      }
      if (data.length === 1){ 
        const props = data[0].sucursales.[getSucursal()] || null;
        if(!props){
          title.current ="Producto Sin Compras";
          setmodalinfoConfig({ isOpen: true,message: ("Nunca ha sido comprado el producto "+itemsAdjust[0]?.id+" por lo tanto no puede haber garantia "),});
          return
        }
        
        if(props?.existencia<=0){
          title.current ="Producto Sin Existencia";
          setmodalinfoConfig({ isOpen: true,message: ("El producto ya no tiene exitencia "+data[0].id),});
          return
        }
        agregarItem(data[0]);
        return
      } 
      if (data.length > 1) {
        let dat = [];
        data.forEach(item=>{
          const props = item?.sucursales?.[getSucursal()];
          if(props?.existencia>0){ dat.push({...item,stock:props.existencia}) }
        })
        setdataproduct(dat);
        setmodalTablaConfig({ isOpen: true });
      }
    }
  };
  
  const funcionExitoMerma=(respuesta)=>{
    closemodalLoading();
    setmodalSuccessConfig({
      isOpen: true,
      message: respuesta.message || "Garantia aplicada",
      title: "Operacion Completada"
    }); 
    selectUser.current.value = "";
    selectMotivo.current.value="";
    setGarantia({personal:"",descripcion:""});
    setItemsAdjust([item0]);
    closeModal({...config,isOpen:false});
  }
  const handleError = (error) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.response?.data?.message,
    });
  }

  const closeModal=()=> {
    setModal({ isOpen: false });
  }
  const openModal=()=>{
    setModal({ isOpen: true });
  }
  const openModalSuccess=(message,titulo)=>{
    setmodalSuccessConfig({
      isOpen: true,
      title: titulo||"Solicitud Completa",
      message: message,        
    });
  }
  const closeModalsuccess=(e)=> {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
  }
  function closeModaldangererror(e) {
      const newdataconfig = { ...modaldangererrorConfig };
      newdataconfig.isOpen = false;
      setmodaldangererrorConfig(newdataconfig);
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModaltabla(e) {
    setmodalTablaConfig({ isOpen: false });
  }
  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
    inputs.current["0id"].focus();
    setItem(item0)
  }

  useEffect(()=>{
    const getUsuarios=async()=>{
      try{
        const resp = await useGetUsuariosAsync();
        const users = resp.data;
        let usuarios = {};
        if(users.length==0){return;}
          users.map(user=>usuarios[user?.id]=user?.nombre)
          setUsuario(usuarios)
      }catch (error){
        closemodalLoading();
        setmodalinfoConfig({ isOpen: true, message: error?.response?.data?.message || error,});
      }
    }
    getUsuarios();
  },[])

  useEffect(() => {
    if(add!==undefined){
      inputs.current["0id"].value = "";
      inputs.current["0id"].focus();
    }
  }, [add])

  return (
    <>
     <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargando Reporte</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>


      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={title.current}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>


     <Modaldanger config={modaldangererrorConfig}>
        <Modaldanger.Message title={"Error"}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      <Modalsuccess config={modalSuccessConfig} tab="200" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            name="modalsuccess"
            tabIndex={"200"}
            className={"btn-green"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modalgeneric bg="bg-mesh" w="size-w-90" h="size-h-90" front="z-30" config={config}>
        <Modalgeneric.Body>
          <div className="w-full h-90/100 relative flex flex-col items-center">
            <div className="w-auto size-2 items-center">
              <label className="text-size font-bold text-white">Personal: </label>
              <Selectormap 
              width={"w-48"}
              catalogo={usuarios}
              inputRef={selectUser}
              getvalor={(valor) => {
                 setGarantia({...garantia,personal:(!valor?.clave?"":valor.clave)})
              }}/>
              <label className=" text-size font-bold text-white">Motivo: </label>
              <Selectormap 
              width={"w-48"}
              catalogo={mot}
              inputRef={selectMotivo}
              getvalor={(valor) => {
                setGarantia({...garantia,descripcion:(!valor?.clave?"":valor.valor)})
              }}/>
            </div>
            <div className="flex flex-grow w-full mx-auto">
              <TableCompra
              searchinput={false}
              columns={[
                {
                  header: "maker/style",
                  width:"w-32",
                  // eslint-disable-next-line react/display-name
                  code: (item,index) => (
                    <div className="flex flex-row w-full gap-2">
                      <input
                        type="text"
                        className="border-table w-full text-rigth uppercase"
                        placeholder=""
                        ref={node=>{inputs.current[item?.index+"id"]=node}}
                        defaultValue={item?.id}
                        readOnly={item?.index!==0}
                        onKeyDown={e=>{
                          const valor = e.target.value.trim().toUpperCase();
                          if((e.key=="Enter"||e.key=="Tab")&&item?.index==0&&item.descripcion==""){
                            if(focusProduct(valor,"cantidad")){return}
                            if(validProductId(valor)){
                              const peticion ={producto:valor,funcionExito:funcionExito,funcionError:handleError};
                              openmodalLoading(e);
                              useGetProduct(peticion);
                            }else{
                              setmodalinfoConfig({isOpen: true,message: "El id "+item.id+" no es válido. " });
                              setItem(item0)
                            }
                          }else if(e.key=="Escape"){
                            setItem(item0);
                            setAdd(!add);
                          }
                        }}
                      />
                        <button
                        className={"text-red-600 transform hover:text-pink-700 hover:scale-110 "+(item?.index==0?"hidden":"")}
                          onClick={(e) => {
                            eliminarItemCompra(item.id);
                          }}
                        >
                          <i className="fas fa-times-circle"></i>
                        </button>
                  </div>
                  ),
                },
                {
                  header: "Description",
                  width:"w-96",
                   // eslint-disable-next-line react/display-name
                  code:(item)=>(
                    <label className="border-table opacity-60 w-full text-left">{item?.descripcion}</label>
                  )
                },
                {
                  header: "Stock",
                  width:"w-28",
                   // eslint-disable-next-line react/display-name
                  code:(item)=>(
                    <LabelNumber twoDecimal={true} className="border-table opacity-60 w-full text-left">{item?.oldExistencia}</LabelNumber>
                  )
                },
                {
                  header: "Qty",
                  width:"w-28 ",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                    <InputPrecioPanel
                    width="w-full"
                    inputRef={node=>{inputs.current[item?.index+"cantidad"]=node}}
                    newValor={moneyToValue(item?.cantidad)}
                    bgMin={item.cantidad>item.oldExistencia||item.cantidad<1}
                    noShowSignal={true}
                    onBlur={(e,x)=>{
                      let cant = e;
                      if((cant<1||isNaN(cant))&&item.id!==""){ inputs.current[item.index+"cantidad"].focus(); }
                      if(cant>item.oldExistencia&&item.id!==""){ inputs.current[item.index+"cantidad"].focus(); }
                      if(cant==item.cantidad){ return;  }
                     
                      changeCantidad({...item,cantidad:cant});
                    }}/>
                  ),
                },
                {
                  header: "Unit Cost",
                  width:"w-28",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                    <div className="w-full flex">
                      <input type="text" className="w-0" 
                      onKeyDown={e=>{
                        if((e.key=="Tab"||e.key=="Enter")&&item?.index==0&&item?.id!==""){
                          addItem(item);
                        }
                      }}/>
                    <Labelmoney twoDecimal={true}>{item.precioCompra}</Labelmoney>
                    </div>
                  ),
                },
                {
                  header: "Amount",
                  width:"w-36",
                  // eslint-disable-next-line react/display-name
                  code: (item) => (
                    <Labelmoney twoDecimal={true}>{item.importe}</Labelmoney>
                  ),
                },
              ]}
              footer={[
                {width:"w-32"},{width:"w-96"},{width:"w-28"},{width:"w-28 bg-ambar-100", code:<p>Total</p>},
                { width:"w-36 bg-ambar-100  ",
                // eslint-disable-next-line react/display-name
                  code: <Labelmoney twoDecimal={true} className="text-size">{itemsAdjust.reduce((a, b) => parseInt(a) + parseInt(b.importe), 0)}</Labelmoney>
                }
              ]}
              data={itemsAdjust}
            />
          </div>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button className="btn-gray2" onClick={e=>{
            closeModal();
          }}>
            Cerrar
          </button>
          <button className="btn-ambar1 w-44"
              onClick={e=>{
                let items = [],cantTotal=0,importeTotal=0,errors=false;
                if(itemsAdjust.length<1||e.detail>1){return}  
                if(!garantia?.personal||!garantia?.descripcion){
                  setmodalinfoConfig({isOpen: true,message: "El personal y el motivo son obligatorios" });
                  return;
                }
                [...itemsAdjust].forEach(product=>{
                  const newitem ={...product,ticket:"S/T",responsable:garantia.personal};
                  delete newitem.oldExistencia;
                  delete newitem.index;

                  if(product.index!==0){ 
                    newitem.cantidad = product.cantidad*-1;
                    newitem.importe = product.importe*-1;
                    items.push(newitem)
                    if(product.cantidad<1){ errors = true }
                  }
                  else if(product.id!==""&&product.descripcion!==""){ 
                    newitem.cantidad = product.cantidad*-1;
                    items.push(newitem) 
                    if(product.cantidad>product.oldExistencia){errors = true}
                  }
                  importeTotal += product.importe*-1;
                  cantTotal += product.cantidad*-1;
                });

                if(errors){
                  setmodalinfoConfig({ 
                    isOpen: true,
                    message: "La cantidad de garantia debe ser mayor a cero y menor que su existencia ",
                  });
                  return
                }
             
                console.log(items)
                openmodalLoading();
                useInsertMerma({items:items,descripcion:garantia.descripcion,cantidadTotal:cantTotal,importeTotal:importeTotal,tipo:"MERMA"},funcionExitoMerma,handleError);
              }}>
                Aplicar Garantia
              </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>


      <Modalgeneric front="z-30" config={modalTablaConfig}>
          <Modalgeneric.Body>
            <Modalgeneric.Title>Elija una opción</Modalgeneric.Title>
            <TableModal
              columns={[
                { header: "Maker/Style", accessor: "id" },
                { header: "Description", accessor: "descripcion" },
                { header: "Stcck", accessor:"stock"},
                { header: "Model", accessor: "medida" },
                { header: "Line", accessor: "linea" },
              ]}
              onclickRow={(item) => {
                agregarItem(item);
                closeModaltabla();
              }}
              data={dataproduct}
            />
          </Modalgeneric.Body>
          <Modalgeneric.Buttons>
            <button
              type="button"
              onClick={closeModaltabla}
              className="btn-gray2"
            >
              Cancelar
            </button>
          </Modalgeneric.Buttons>
        </Modalgeneric>
    </>
  );
};


const mapStateToProps = (state) => ({
  PropsReportes: state.PropsReportes,
  Datos: state.Datos,
  Categorias: state.Catalogos.CATEGORIAS.opciones,
  Motivos: state.Catalogos.GARANTIAS,
});

export default connect (mapStateToProps,null)(ModalMerma);