import React, { useState } from "react";

const Table = (props) => {
  const onclickRow = props.onclickRow || ((item) => {});
  const columns = props.columns || [{}];
  const footer = props.footer || [{}];
  const bgColumn = props.bgColumn || "";
  const data = props.data || [];
  const [field, setfield] = useState("");
  const [desc, setdesc] = useState(false);

  function byfield(a, b) {
    if (desc) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
    } else {
      if (a[field] < b[field]) return 1;
      if (a[field] > b[field]) return -1;
    }
    return 0;
  }

  return (
    <div className="absolute flex flex-col break-words overflow-x-auto overflow-y-auto h-full w-full mx-auto landscape:mt-0 " onKeyDown={props?.onKeyDown}>
      <div className="overflow-x-auto overflow-y-auto mt-1 relative flex flex-col min-h-0 min-w-0  max-w-screen-2xl mb-1 break-words rounded bg-white border-0 h-full">
        <table className="overflow-x-auto overflow-y-auto ">
          <thead className="sticky top-0 rounded-full bg-transparent text-white text-sm font-bold align-middle cursor-pointer uppercase">
            <tr>
              {columns
                ? columns.map((item, index) => (
                    <th
                      key={index}
                      onClick={(e) => {
                        //setdesc(!desc);
                        //setfield(item.accessor);
                      }}
                      className={(bgColumn||"bg-blue-800")+" py-1 border-gray-400 "+(index==0?"rounded-tl-md ":(index==columns.length-1?" rounded-tr-md ":"border-l-2 border-r-2"))}
                      data-priority={index}
                    >
                      {item.header}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody className="font-normal md:font-semibold ">
            {data
              ? data
                  .sort(byfield)
                  .map((item, index1) => (
                    <tr
                      key={index1}
                    className={"cursor-pointer hover:bg-blue-200 focus:bg-indigo-400 h-7 lg:h-8 font-semibold "+(index1 % 2 === 0 ? "bg-sky-100" : "bg-white")+(item?.repeat&&item?.cantidad!==item?.oldCant?" bg-ambar-200":((item?.cantidad<=0||!item?.cantidad)?" bg-red-200":"")) }
                      onClick={(e) => {
                        onclickRow(item);
                      }}
                    >
                      {props.columns.map((itemcolumn, index2) => (
                        <td key={index2} className={itemcolumn.width + " px-1 md:px-2 align-middle  "} >
                          {itemcolumn.code
                            ? itemcolumn.code(item)
                            : <label className="border-table w-full" readOnly={true}>{item[itemcolumn.accessor]}</label>}
                        </td>
                      ))}
                    </tr>
                  ))
              : null}
          </tbody>
           <tfoot className={(bgColumn||"bg-blue-800")+" w-full bottom-0 sticky border rounded-b-md text-white"}>
            <tr >
              {footer
                ? footer.map((item, index) => (
                    <th
                      key={index}
                      className={item.width+" px-2"}
                      data-priority={index}
                    >
                      {item.code}
                    </th>
                  ))
                : null}
            </tr>
          </tfoot> 
        </table>
       
      </div>
    </div>
  );
};

export default Table;
