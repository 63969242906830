import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Sucursal } from "../../services/functions.js";
import Newlogo from "../../static/img/logonew.jsx";
import {motion} from 'framer-motion'
import PagadoSvg from "../../static/img/Pagado";
import Labelmoney from "../labelmoney";
import { returnRuta } from "../../redux/Actions.js";
import HeaderTicket from "./HeaderTicket.jsx";
import FooterTicket from "./FooterTicket.jsx";
import { btnScale, btnTap } from "../../static/motionStyle.js";

const TicketPagado = ({ Ticket, delRuta,text }) => {
  const [texto,setTexto] = useState(false);

  useEffect(() => { window.print();}, []);

  return (
    <>
    <div className="mx-2 75mm">
        <div className="w-full text-2xs">
          <div className="grid grid-flow-col justify-items-stretch w-full">
            {(Ticket?.pagado==Ticket.total)?
              <div className="w-40 justify-self-end" 
              onClick={e=>{ setTexto(!texto);
              }}>
                <PagadoSvg bg="bg-transparent" text="text-red-800"/>
              </div>
              : <motion.button
              whileHover={btnScale}
              whileTap={btnTap } 
              className="text-lg justify-self-end font-black uppercase"
              onClick={e=>{
                setTexto(!texto);
              }}>
                Credito
              </motion.button>
            }
            <motion.button
            whileHover={btnScale}
            whileTap={btnTap }
            onClick={(e) => {delRuta();}}
            className={ "w-[80px] justify-self-end h-14 border-none leading-relaxed inline-block" }
            >
              <Newlogo corona="#00ace5" corona1="#00C9E5"  text="#000000"/>
            </motion.button>
          </div>
          <HeaderTicket Ticket={Ticket} />
            <table className="w-full  font-semibold mt-1">
              <thead >
                <tr>
                  <th className="text-center">Cant</th>
                  <th className="text-center">Descripción</th>
                  <th className="text-right w-14">Precio U</th>
                  <th className="text-right w-10">Desc</th>
                  <th className="text-right w-14">Subtotal</th>
                </tr>
              </thead>
              <tbody >
                {Ticket?.items
                  ? Ticket.items.map((item, index) => {
                    if(item?.servicios){
                      return item.servicios.map((serv,i)=>{
                        return <tr key={serv.id+i}  >
                          <td className=" text-center pt-1">{serv.cantidadVendida}</td>
                          <td className=" text-start text-3xs pt-1">{texto?serv.descripcion:serv.descripcion.substring(0,35)}</td>
                          <td className="w-12 text-right pt-1"><Labelmoney twoDecimal={true}>{serv.precioVenta}</Labelmoney></td>
                          <td className="w-10 text-right pt-1">{(serv.descuento||0) + "%"}</td>
                          <td className="w-16 text-right pt-1"><Labelmoney twoDecimal={true}>{serv.importe}</Labelmoney></td>
                        </tr>
                      })
                    }else{
                      return (<tr key={index}>
                        <td className=" text-center pt-1">{item.cantidadVendida}</td>
                        <td className=" text-start text-3xs pt-1">{(item?.id?item.id+" ":"")+(texto?item.descripcion:item.descripcion.substring(0,35))}</td>
                        <td className="w-12 text-right pt-1"><Labelmoney twoDecimal={true}>{item.precioVenta}</Labelmoney></td>
                        <td className="w-10 text-right pt-1">{item.descuento + "%"}</td>
                        <td className="w-16 text-right pt-1"><Labelmoney twoDecimal={true}>{item.importe}</Labelmoney></td>
                      </tr>)
                    }
                  })
                  : null}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-center"> {Ticket?.numeroArticulos || ""}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
            <FooterTicket Ticket={Ticket} />
            {Sucursal('ticket')?.header?
              <p className="uppercase w-full text-center">{Sucursal('ticket').header}</p>
            :""}
            {Sucursal('ticket')?.productos.length?
              <div className="w-full text-center mt-3">
                <p className="uppercase w-full text-center font-black mb-1">Nuevos Productos</p>
                {Sucursal('ticket').productos.map((prod,index)=>(<p key={index} className="uppercase w-full text-center">{prod}</p>))}
              </div>
            :""}
            {Sucursal('ticket')?.footer?
              <p className="w-full text-center mt-3">{Sucursal('ticket').footer}</p>
            :""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  Ticket: state.Ticket,
});

const mapDispatchToProps = (dispatch) => ({
  delRuta: () => dispatch(returnRuta()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketPagado);
