import React,{useState,useEffect} from "react";
import PanelTablaEspecial from "../components/PedidoEspecial/PanelTablaEspecial";
import InsertPedidoEspecial from "../components/PedidoEspecial/InsertPedidoEspecial";
import HeaderPedidoEspecial from "../components/PedidoEspecial/HeaderPedidoEspecial";
import { useGetSomething } from "../Hooks/useApis";
import Container from "../components/Container";
import InputClient from "../components/inputClient";
import DatosClienteVenta from "../components/DatosClienteVenta";
import { connect } from "react-redux";
import Toggle from "../components/Toggle";
import BuscaTicketPes from "../components/PedidoEspecial/BuscaTicketPes";


const Pedido = ({setHeaderPes,headerPes}) => {
  const [proveedores,setProveedores]=useState([]);
  
  useEffect(() => {
    if(proveedores.length!==0){return; }
    
    useGetSomething("SUPPLIER","",handleSuccessfulSom,handleError);
  }, [proveedores])

  const handleSuccessfulSom = (data) => {
    let proveedores =[];
    data.datos.forEach(element => {proveedores.push(element.id);});
    setProveedores([proveedores,data.datos]);
  };
  const handleError = (error) => {
    
  }
  
  return (
    <Container >
      <div className="flex flex-col w-full gap-3 ">
        <div >
          <InputClient/>
          <DatosClienteVenta />
        </div>
        <InsertPedidoEspecial proveedores={proveedores}/>
        <div className="flex flex-row items-center gap-2 ">
          <HeaderPedidoEspecial  />
          <button 
          className={"btn-green w-38 ml-4"}
          onClick={e=>{
            setHeaderPes({...headerPes,crearPedido:!headerPes.crearPedido});
          }}>
            Crear PES
          </button>
          <button 
          className={(headerPes?.opcion=="surtir"?"btn-green1 w-38 ml-4":"hidden")}
          onClick={e=>{
            setHeaderPes({...headerPes,opcion:"pedidos"});
          }}>
            Pedidos
          </button>
          <button 
          className={(headerPes?.opcion=="pedidos"?"btn-ambar1 w-38 ml-4":"hidden")}
          onClick={e=>{
            setHeaderPes({...headerPes,opcion:"surtir"});
          }}>
            Por Surtir
          </button>
          <label htmlFor="toggle" className="font-semibold ">Ver PrecioCompra</label>
          <Toggle name="activo" checked={headerPes.showPrecioCompra} onClick={(e,isOn)=>{
            setHeaderPes({...headerPes,showPrecioCompra:isOn})
          }}/>
          <div className="w-24">
            <BuscaTicketPes/>
          </div> 
          
        </div>
      </div>
       
       <div className="relative h-70/100 md:h-89/100 w-full justify-start" onContextMenu={e=>{e.preventDefault()}}>
          <PanelTablaEspecial proveedores={proveedores}/>
      </div> 
    </Container>
  );
};


const mapDispatchToProps = (dispatch) => ({
  setHeaderPes:(pedido)=> dispatch({type:"SET_HEADER_PES",pedido:pedido}),
});

const mapStateToProps = (state) => ({
  headerPes: state.HeaderPes,
  itemsPedido: state.itemsPedidoEspecial,
  PropsReportes: state.PropsReportes,
});

export default connect(mapStateToProps, mapDispatchToProps)(Pedido);
